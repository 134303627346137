import React from 'react'
import Plus from '@components/Icons/Plus.jsx'
import Minus from '@components/Icons/Minus.jsx'

import * as nav from '@data/navdata'

const SubnavGifts = ({
    activeSubSubnav,
    setActiveSubSubnav,
    handleToggleSubSubnav,
}) => {
    return (
        <nav className="subnav-wrap" role="navigation">
            <ul className="nostyle">
                <li className="py-1 text-sm font-normal">
                    <a href={nav.baby_gifts}>Shop All Gifts</a>
                </li>
                <li className="py-1 text-sm font-normal">
                    <a href={nav.baby_blankets}>Blankets</a>
                </li>
                <li className="py-1 text-sm font-normal">
                    <a href={nav.cuddle_box}>Hospital Cuddle Box™</a>
                </li>
                <li className="py-1 text-sm font-normal">
                    <a href={nav.baby_first_year}>Baby's First Year Box</a>
                </li>
                <li className="py-1 text-sm font-normal">
                    <a href={nav.modern_layette}>Modern Layette Box</a>
                </li>
                <li className="py-1 text-sm font-normal">
                    <a href={nav.snuggle_box}>Snuggle Cuddle Box</a>
                </li>
                <li className="py-1 text-sm font-normal">
                    <a href={nav.toys}>Toys</a>
                </li>
                <li className="py-1 text-sm font-normal">
                    <a href={nav.books}>Books</a>
                </li>
                <li className="py-1 text-sm font-normal">
                    <a href={nav.embroidery_shop}>Personalized Gifts</a>
                </li>
            </ul>
            <div
                className="pl-3 subsubnav subnav-gifts-bundles mt-2"
                data-subnav="gift_bundles"
            >
                <div
                    className="menu-section-header flex items-center w-full py-1"
                    data-subnav="gift_bundles"
                    onClick={handleToggleSubSubnav}
                >
                    <span className="menu-section-text">Bundles</span>{' '}
                    <span className="ml-auto">
                        {activeSubSubnav &&
                        activeSubSubnav.gift_bundles === true ? (
                            <Minus width="w-[16px]" />
                        ) : (
                            <Plus width="w-[18px]" />
                        )}
                    </span>
                </div>
                <div
                    className={`subsubnav subsubnav-gift-bundles ${
                        activeSubSubnav && activeSubSubnav.gift_bundles
                            ? 'block'
                            : 'hidden'
                    }`}
                    data-subsubnav="gift_bundles"
                >
                    <ul className="nostyle">
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.bundles}>Shop All Bundles</a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.essential_baby_bundle}>
                                Essential Baby Bundle
                            </a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.muslin_swaddle}>
                                Muslin Swaddle 3-Pack
                            </a>
                        </li>


                        <li className="py-1 text-sm font-normal">
                            <a href={nav.outfits_bundles}>Outfits</a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.lets_dance_bundle}>Let's Dance Dress Bundle</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div
                className="pl-3 subsubnav subnav-gift-guides mt-2 mb-3"
                data-subnav="gift_guides"
            >
                <div
                    className="menu-section-header flex items-center w-full py-1"
                    data-subnav="gift_guides"
                    onClick={handleToggleSubSubnav}
                >
                    <span className="menu-section-text">Gift Guides</span>{' '}
                    <span className="ml-auto">
                        {activeSubSubnav &&
                        activeSubSubnav.gift_guides === true ? (
                            <Minus width="w-[16px]" />
                        ) : (
                            <Plus width="w-[18px]" />
                        )}
                    </span>
                </div>
                <div
                    className={`subsubnav subsubnav-gear-moments ${
                        activeSubSubnav && activeSubSubnav.gift_guides
                            ? 'block'
                            : 'hidden'
                    }`}
                    data-subsubnav="gift_guides"
                >
                    <ul className="nostyle">
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.pregnancy}>Pregnancy</a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.baby_shower_welcome_home}>
                                Baby Shower + Welcome Home
                            </a>
                        </li>

                        <li className="py-1 text-sm font-normal">
                            <a href={nav.first_birthday_gifts}>
                                Birthday Gifts
                            </a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.gifts_for_mom}>Gifts For Mom</a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.baby_registry_essentials}>
                                Baby Registry Essentials
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default SubnavGifts
