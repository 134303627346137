import * as nav from '@data/navdata'
import Plus from '@components/Icons/Plus.jsx'
import Minus from '@components/Icons/Minus.jsx'

const SubnavMaternity = ({
    activeSubSubnav,
    setActiveSubSubnav,
    handleToggleSubSubnav,
}) => {
    return (
        <nav className="subnav-wrap" role="navigation">
            <ul className="nostyle">
                <li className="py-1 text-sm font-normal">
                    <a href={nav.maternity}>Shop All Maternity</a>
                </li>
                <li className="py-1 text-sm font-normal">
                    <a href={nav.maternity_new_arrivals}>
                        Maternity New Arrivals
                    </a>
                </li>
                <li className="py-1 text-sm font-normal">
                    <a href={nav.loungewear}>Loungewear + Robes</a>
                </li>
                <li className="py-1 text-sm font-normal">
                    <a href={nav.maternity_dresses}>Dresses</a>
                </li>
                <li className="py-1 text-sm font-normal">
                    <a href={nav.hospital_essentials}>Hospital Essentials</a>
                </li>
                <li className="py-1 text-sm font-normal">
                    <a href={nav.maternity_health}>Maternity Health</a>
                </li>
                <li className="py-1 text-sm font-normal">
                    <a href={nav.nursing_feeding}>Nursing</a>
                </li>
                <li className="py-1 text-sm font-normal">
                    <a href={nav.mommy_me}>Mini + Me</a>
                </li>
                <li className="py-1 text-sm font-normal">
                    <a href={nav.diaper_bags}>Totes + Bags</a>
                </li>
            </ul>
            <div
                className="pl-3 subsubnav subnav-maternity-moments mt-2 mb-3"
                data-subnav="maternity_moments"
            >
                <div
                    className="menu-section-header flex items-center w-full py-1"
                    data-subnav="maternity_moments"
                    onClick={handleToggleSubSubnav}
                >
                    <span className="menu-section-text">Shop By Moments</span>{' '}
                    <span className="ml-auto">
                        {activeSubSubnav &&
                        activeSubSubnav.maternity_moments === true ? (
                            <Minus width="w-[16px]" />
                        ) : (
                            <Plus width="w-[18px]" />
                        )}
                    </span>
                </div>
                <div
                    className={`subsubnav subsubnav-maternity-moments ${
                        activeSubSubnav && activeSubSubnav.maternity_moments
                            ? 'block'
                            : 'hidden'
                    }`}
                    data-subsubnav="maternity_moments"
                >
                    <ul className="nostyle">
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.hospital_bag}>
                                Packing Your Hospital Bag
                            </a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.postpartum}>
                                Postpartum
                            </a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.pregnancy}>Pregnancy</a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.trimester_2_3}>Trimesters 2–3</a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.mommy_me}>Matching Family</a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.first_feeds}>First Feeds + Nursing</a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.baby_registry_essentials}>
                                Baby Registry Essentials
                            </a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.setting_up_nursery}>
                                Setting Up Your Nursery
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default SubnavMaternity
