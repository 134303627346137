import React from 'react'
import Plus from '@components/Icons/Plus.jsx'
import Minus from '@components/Icons/Minus.jsx'

import * as nav from '@data/navdata'

const SubnavGear = ({
    activeSubSubnav,
    setActiveSubSubnav,
    handleToggleSubSubnav,
}) => {
    return (
        <nav className="subnav-wrap" role="navigation">
            <ul className="nostyle">
                <li className="py-1 text-sm font-normal">
                    <a href={nav.gear_travel}>Shop All Gear</a>
                </li>
                <li className="py-1 text-sm font-normal">
                    <a href={nav.gear_new}>Gear New Arrivals</a>
                </li>
                <li className="py-1 text-sm font-normal">
                    <a href={nav.diaper_bags}>Diaper Bags + Organizers</a>
                </li>
                <li className="py-1 text-sm font-normal">
                    <a href={nav.strollers}>Strollers</a>
                </li>
                <li className="py-1 text-sm font-normal">
                    <a href={nav.bouncers_loungers}>Bouncers + Loungers</a>
                </li>
                <li className="py-1 text-sm font-normal">
                    <a href={nav.playards}>Play Yards</a>
                </li>
                <li className="py-1 text-sm font-normal">
                    <a href={nav.carriers}>Carriers + Car Seats</a>
                </li>
                <li className="py-1 text-sm font-normal">
                    <a href={nav.activity_centers_play_gyms}>
                        Activity Centers + Play Gyms
                    </a>
                </li>
            </ul>
            <div
                className="pl-3 subsubnav subnav-gear-nursing mt-2"
                data-subnav="gear_nursing"
            >
                <div
                    className="menu-section-header flex items-center w-full py-1"
                    data-subnav="gear_nursing"
                    onClick={handleToggleSubSubnav}
                >
                    <span className="menu-section-text">Nursing + Feeding</span>{' '}
                    <span className="ml-auto">
                        {activeSubSubnav &&
                        activeSubSubnav.gear_nursing === true ? (
                            <Minus width="w-[16px]" />
                        ) : (
                            <Plus width="w-[18px]" />
                        )}
                    </span>
                </div>
                <div
                    className={`subsubnav subsubnav-gear-moments ${
                        activeSubSubnav && activeSubSubnav.gear_nursing
                            ? 'block'
                            : 'hidden'
                    }`}
                    data-subsubnav="gear_nursing"
                >
                    <ul className="nostyle">
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.nursing_feeding}>Nursing + Feeding</a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.high_chairs}>High Chairs</a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.baby_pacifiers}>
                                Pacifiers + Teethers
                            </a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.nursing_pillows}>Nursing Pillows</a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.bottles}>Baby Bottles</a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.sterilizers}>Sterilizers</a>
                        </li>

                        <li className="py-1 text-sm font-normal">
                            <a href={nav.plates_cups}>Plates, Mats + Cups</a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.baby_burp_cloths}>
                                Burp Cloths + Bibs
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div
                className="pl-3 subsubnav subnav-gear-moments mt-2 mb-3"
                data-subnav="gear_moments"
            >
                <div
                    className="menu-section-header flex items-center w-full py-1"
                    data-subnav="gear_moments"
                    onClick={handleToggleSubSubnav}
                >
                    <span className="menu-section-text">Shop By Moment</span>{' '}
                    <span className="ml-auto">
                        {activeSubSubnav &&
                        activeSubSubnav.gear_moments === true ? (
                            <Minus width="w-[16px]" />
                        ) : (
                            <Plus width="w-[18px]" />
                        )}
                    </span>
                </div>
                <div
                    className={`subsubnav subsubnav-gear-moments ${
                        activeSubSubnav && activeSubSubnav.gear_moments
                            ? 'block'
                            : 'hidden'
                    }`}
                    data-subsubnav="gear_moments"
                >
                    <ul className="nostyle">
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.packing_your_diaper_bag}>
                                Packing Your Diaper Bag
                            </a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.first_feeds}>First Feeds + Nursing</a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.starting_solids}>Starting Solids</a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.on_the_go}>
                                On The Go
                            </a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.health_safety}>
                                Health + Safety
                            </a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.baby_registry_essentials}>
                                Baby Registry Essentials
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default SubnavGear
