import React from 'react'
import Plus from '@components/Icons/Plus.jsx'
import Minus from '@components/Icons/Minus.jsx'

import * as nav from '@data/navdata'

const SubnavBaby = ({
    activeSubSubnav,
    setActiveSubSubnav,
    handleToggleSubSubnav,
}) => {
    return (
        <nav className="subnav-wrap" role="navigation">
            <ul className="nostyle">
                <li className="py-1 text-sm font-normal">
                    <a href={nav.kids_clothes}>Shop All Kids</a>
                </li>
                <li className="py-1 text-sm font-normal">
                    <a href={nav.kids_new}>Kids New Arrivals</a>
                </li>
                <li className="py-1 text-sm font-normal">
                    <a href={nav.kids_shirts}>Shirts + Tops</a>
                </li>
                <li className="py-1 text-sm font-normal">
                    <a href={nav.kids_pants}>Pants + Leggings</a>
                </li>
                <li className="py-1 text-sm font-normal">
                    <a href={nav.kids_dresses_rompers}>Dresses + Rompers</a>
                </li>
                <li className="py-1 text-sm font-normal">
                    <a href={nav.kids_shorts}>Shorts</a>
                </li>
                <li className="py-1 text-sm font-normal">
                    <a href={nav.kids_sweaters}>Sweaters + Sweatshirts</a>
                </li>
                <li className="py-1 text-sm font-normal">
                    <a href={nav.kids_pajamas}>Pajamas</a>
                </li>
                <li className="py-1 text-sm font-normal">
                    <a href={nav.kids_swimwear}>Swimwear</a>
                </li>
                <li className="py-1 text-sm font-normal">
                    <a href={nav.kids_jackets}>Jackets + Outerwear</a>
                </li>
                <li className="py-1 text-sm font-normal">
                    <a href={nav.kids_blankets}>Blankets</a>
                </li>
                <li className="py-1 text-sm font-normal">
                    <a href={nav.kids_underwear}>Underwear + Socks</a>
                </li>
            </ul>
            <div
                className="pl-3 subsubnav subnav-baby-sizes mt-2"
                data-subnav="kids_sizes"
            >
                <div
                    className="menu-section-header flex items-center w-full py-1"
                    data-subnav="kids_sizes"
                    onClick={handleToggleSubSubnav}
                >
                    <span className="menu-section-text">Shop By Size</span>{' '}
                    <span className="ml-auto">
                        {activeSubSubnav &&
                        activeSubSubnav.kids_sizes === true ? (
                            <Minus width="w-[16px]" />
                        ) : (
                            <Plus width="w-[18px]" />
                        )}
                    </span>
                </div>
                <div
                    className={`subsubnav subsubnav-kids-sizes ${
                        activeSubSubnav && activeSubSubnav.kids_sizes
                            ? 'block'
                            : 'hidden'
                    }`}
                    data-subsubnav="baby_moments"
                >
                    <ul className="nostyle">
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.kids_sizes_6_7}>6/7</a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.kids_sizes_8}>8</a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.kids_sizes_10}>10</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div
                className="pl-3 subsubnav subnav-kids-moments mt-2 mb-3"
                data-subnav="kids_moments"
            >
                <div
                    className="menu-section-header flex items-center w-full py-1"
                    data-subnav="kids_moments"
                    onClick={handleToggleSubSubnav}
                >
                    <span className="menu-section-text">Shop By Moments</span>{' '}
                    <span className="ml-auto">
                        {activeSubSubnav &&
                        activeSubSubnav.kids_moments === true ? (
                            <Minus width="w-[16px]" />
                        ) : (
                            <Plus width="w-[18px]" />
                        )}
                    </span>
                </div>
                <div
                    className={`subsubnav subsubnav-kids-moments ${
                        activeSubSubnav && activeSubSubnav.kids_moments
                            ? 'block'
                            : 'hidden'
                    }`}
                    data-subsubnav="kids_moments"
                >
                    <ul className="nostyle">
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.starting_school}>Starting School</a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.toddler_play}>Play</a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.special_occasions}>
                                Special Occasions
                            </a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.mommy_me}>Matching Family</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default SubnavBaby
