import React, { useState, useEffect, Fragment } from 'react'
import { useSwipeable } from 'react-swipeable';
import { useStore } from '@nanostores/react'
import { isMobileMenuOpen } from '@stores/menuStore'
import { userData } from '@stores/userStore'
import Hamburger from '@components/Icons/Hamburger.jsx'
import CloseX from '@components/Icons/Close-X.jsx'
import Plus from '@components/Icons/Plus.jsx'
import Minus from '@components/Icons/Minus.jsx'
import DisneyLogo from '@components/Icons/DisneyLogo.jsx'
import Location from '@components/Icons/Location.jsx'
import User from '@components/Icons/User.jsx'
import UserSolid from '@components/Icons/UserSolid.jsx'
import EmbroideredBlanket from '@components/Icons/EmbroideredBlanket.jsx'
import Calendar from '@components/Icons/Calendar.jsx'
import Document from '@components/Icons/Document.jsx'
import OpenBox from '@components/Icons/OpenBox.jsx'
import Gift from '@components/Icons/Gift.jsx'
import Chat from '@components/Icons/Chat.jsx'
import SubnavMaternity from './SubnavMaternity.jsx'
import SubnavBaby from './SubnavBaby.jsx'
import SubnavToddler from './SubnavToddler.jsx'
import SubnavKids from './SubnavKids.jsx'
import SubnavNursery from './SubnavNursery.jsx'
import SubnavGear from './SubnavGear.jsx'
import SubnavGifts from './SubnavGifts.jsx'
import SubnavHelp from './SubnavHelp.jsx'
import SubnavAccount from './SubnavAccount.jsx'

import * as nav from '@data/navdata'

const NavMobileMenu = () => {
    const $isMobileMenuOpen = useStore(isMobileMenuOpen)
    const $userData = useStore(userData || null)

    const [haveUser, setHaveUser] = useState(false)

    useEffect(() => {
        if ($userData && $userData.isLoggedIn) {
            setHaveUser(true)
        } else {
            setHaveUser(false)
        }
    }, [$userData])


    const [activeSubnav, setActiveSubnav] = useState({
        maternity: false,
        baby: false,
        toddler: false,
        kids: false,
        nursery: false,
        gear: false,
        gifts: false,
        help: false,
        account: false,
    })

    const [activeSubSubnav, setActiveSubSubnav] = useState({
        maternity_moments: false,
        maternity_mom: false,
        baby_moments: false,
        baby_sizes: false,
        kids_moments: false,
        kids_sizes: false,
        health_safety: false,
        furniture_decor: false,
        nursery_moments: false,
        gear_moments: false,
        gift_bundles: false,
        gift_guides: false,
    })

    const handleMenuToggle = () => {
        isMobileMenuOpen.set(!$isMobileMenuOpen)

        const main = document.querySelector('main')
        const menuOpen = isMobileMenuOpen.get()

        if (menuOpen) {
            main.classList.add('menu-overlay')

            setActiveSubnav({
                maternity: false,
                baby: false,
                kids: false,
                toddler: false,
                nursery: false,
                gear: false,
                gifts: false,
                help: false,
                account: false,
            })

            setActiveSubSubnav({
                maternity_moments: false,
                maternity_mom: false,
                baby_moments: false,
                baby_nursery: false,
                baby_sizes: false,
                toddler_moments: false,
                toddler_sizes: false,
                kids_moments: false,
                kids_sizes: false,
                health_safety: false,
                nursery_moments: false,
                furniture_decor: false,
                gear_nursing: false,
                gear_moments: false,
                gift_bundles: false,
                gift_guides: false,
            })

        } else {
            main.classList.remove('menu-overlay')
        }
    }

    const handleToggleSubnav = (e) => {
        const nav = e.currentTarget.dataset.nav

        setActiveSubnav({
            ...activeSubnav,
            [nav]: !activeSubnav[nav],
        })
    }

    const handleToggleSubSubnav = (e) => {
        const nav = e.currentTarget.dataset.subnav

        setActiveSubSubnav({
            ...activeSubSubnav,
            [nav]: !activeSubSubnav[nav],
        })
    }

    const handleSwiped = (e) => {
        console.log('e', e)

        if (e.dir === 'Left') {
            isMobileMenuOpen.set(false)

            const main = document.querySelector('main')
            const menuOpen = isMobileMenuOpen.get()
            main.classList.remove('menu-overlay')
        }
    };

    const menuHandlers = useSwipeable({
        onSwiped: handleSwiped,
        touchEventOptions: { passive: false },

    });

    return (
        <div className="mobile-menu-wrap" {...menuHandlers}>
            <div
                className="menu-toggle mr-2 flex-items-center ml-3"
                onClick={handleMenuToggle}
            >
                <span className="screen-reader-text sr-only">Menu</span>
                {$isMobileMenuOpen ? (
                    <span className="close-x menu-close relative top-[1px]">
                        <CloseX width="w-[18px]" />
                    </span>
                ) : (
                    <span className="bars hamburger-menu relative top-[1px]">
                        <Hamburger width="w-[18px]" />
                    </span>
                )}

                <span className="times hidden">&times;</span>
            </div>
            <div
                className={`mobile-menu-outer absolute top-[64px] left-0 w-10/12 bg-[#FFFAF5] p-3 h-auto z-10 ${
                    $isMobileMenuOpen ? 'flex' : 'hidden'
                }`}
            >
                <div className="mobile-menu-inner w-full">
                    <nav className="mobile-menu w-full">
                        <div className="mobile-menu-sections w-full">
                            <div className="mobile-menu-section mobile-menu-disney w-full">
                                <div className="menu-section-header flex items-center w-full pb-2 menu-section-link no-subnav" data-nav="sale">
                                    <a className="relative -left-1" href="/collections/disney-monica-andy" rel="prefetch">
                                        <DisneyLogo width="w-[60px]" />
                                        <span className="ml-3 menu-section-text sr-only">Our Disney Collections</span>
                                    </a>
                                </div>
                            </div>

                            <div className="mobile-menu-section mobile-menu-new-arrivals w-full">
                                <div className="menu-section-header flex items-center w-full py-2 menu-section-link no-subnav" data-nav="sale">
                                    <a href="/collections/new-arrivals" rel="prefetch"><span className="menu-section-text ">New Arrivals</span></a>
                                </div>
                            </div>

                            <div className="mobile-menu-section mobile-menu-baby w-full">
                                <div className="menu-section-header flex items-center w-full py-2" data-nav="baby" onClick={handleToggleSubnav}>
                                    <span className="menu-section-text ">Baby (0–24M)</span> <span className="ml-auto">{activeSubnav && activeSubnav.baby === true ? <Minus width="w-[16px]" /> : <Plus width="w-[18px]" />}</span>
                                </div>
                                <div className={`subnav subnav-baby ${activeSubnav && activeSubnav.baby ? 'block' : 'hidden'}`} data-subnav="baby">
                                    <SubnavBaby activeSubSubnav={activeSubSubnav} setActiveSubSubnav={setActiveSubSubnav} handleToggleSubSubnav={handleToggleSubSubnav} />
                                </div>
                            </div>
                            <div className="mobile-menu-section mobile-menu-toddler w-full">
                                <div className="menu-section-header flex items-center w-full py-2" data-nav="toddler" onClick={handleToggleSubnav}>
                                    <span className="menu-section-text ">Toddler (2T–5T)</span> <span className="ml-auto">{activeSubnav && activeSubnav.toddler === true ? <Minus width="w-[16px]" /> : <Plus width="w-[18px]" />}</span>
                                </div>
                                <div className={`subnav subnav-toddler ${activeSubnav && activeSubnav.toddler ? 'block' : 'hidden'}`} data-subnav="toddler">
                                    <SubnavToddler activeSubSubnav={activeSubSubnav} setActiveSubSubnav={setActiveSubSubnav} handleToggleSubSubnav={handleToggleSubSubnav} />
                                </div>
                            </div>
                            <div className="mobile-menu-section mobile-menu-kids w-full">
                                <div className="menu-section-header flex items-center w-full py-2" data-nav="kids" onClick={handleToggleSubnav}>
                                    <span className="menu-section-text ">Kids (6T-10Y)</span> <span className="ml-auto">{activeSubnav && activeSubnav.kids === true ? <Minus width="w-[16px]" /> : <Plus width="w-[18px]" />}</span>
                                </div>
                                <div className={`subnav subnav-kids ${activeSubnav && activeSubnav.kids ? 'block' : 'hidden'}`} data-subnav="kids">
                                    <SubnavKids activeSubSubnav={activeSubSubnav} setActiveSubSubnav={setActiveSubSubnav} handleToggleSubSubnav={handleToggleSubSubnav} />
                                </div>
                            </div>
                            <div className="mobile-menu-section mobile-menu-maternity w-full">
                                <div className="menu-section-header flex items-center w-full py-2" data-nav="maternity" onClick={handleToggleSubnav}>
                                    <span className="menu-section-text ">Maternity + Mom</span> <span className="ml-auto">{activeSubnav && activeSubnav.maternity === true ? <Minus width="w-[16px]" /> : <Plus width="w-[18px]" />}</span>
                                </div>
                                <div className={`subnav subnav-maternity ${activeSubnav && activeSubnav.maternity ? 'block' : 'hidden'}`} data-subnav="maternity">
                                    <SubnavMaternity activeSubSubnav={activeSubSubnav} setActiveSubSubnav={setActiveSubSubnav} handleToggleSubSubnav={handleToggleSubSubnav} />
                                </div>
                            </div>
                            <div className="mobile-menu-section mobile-menu-nursery w-full">
                                <div className="menu-section-header flex items-center w-full py-2" data-nav="nursery" onClick={handleToggleSubnav}>
                                    <span className="menu-section-text ">Nursery</span> <span className="ml-auto">{activeSubnav && activeSubnav.nursery === true ? <Minus width="w-[16px]" /> : <Plus width="w-[18px]" />}</span>
                                </div>
                                <div className={`subnav subnav-nursery ${activeSubnav && activeSubnav.nursery ? 'block' : 'hidden'}`} data-subnav="nursery">
                                    <SubnavNursery activeSubSubnav={activeSubSubnav} setActiveSubSubnav={setActiveSubSubnav} handleToggleSubSubnav={handleToggleSubSubnav} />
                                </div>
                            </div>
                            <div className="mobile-menu-section mobile-menu-gear w-full">
                                <div className="menu-section-header flex items-center w-full py-2" data-nav="gear" onClick={handleToggleSubnav}>
                                    <span className="menu-section-text ">Gear</span> <span className="ml-auto">{activeSubnav && activeSubnav.gear === true ? <Minus width="w-[16px]" /> : <Plus width="w-[18px]" />}</span>
                                </div>
                                <div className={`subnav subnav-gear ${activeSubnav && activeSubnav.gear ? 'block' : 'hidden'}`} data-subnav="gear">
                                    <SubnavGear activeSubSubnav={activeSubSubnav} setActiveSubSubnav={setActiveSubSubnav} handleToggleSubSubnav={handleToggleSubSubnav} />
                                </div>
                            </div>
                            <div className="mobile-menu-section mobile-menu-gifts w-full pb-2">
                                <div className="menu-section-header flex items-center w-full py-2" data-nav="gifts" onClick={handleToggleSubnav}>
                                    <span className="menu-section-text ">Gifts</span> <span className="ml-auto">{activeSubnav && activeSubnav.gifts === true ? <Minus width="w-[16px]" /> : <Plus width="w-[18px]" />}</span>
                                </div>
                                <div className={`subnav subnav-gifts ${activeSubnav && activeSubnav.gifts ? 'block' : 'hidden'}`} data-subnav="gifts">
                                    <SubnavGifts activeSubSubnav={activeSubSubnav} setActiveSubSubnav={setActiveSubSubnav} handleToggleSubSubnav={handleToggleSubSubnav} />
                                </div>
                            </div>
                            <div className="mobile-menu-section mobile-menu-sale w-full">
                                <div className="menu-section-header flex items-center w-full pb-2 menu-section-link no-subnav" data-nav="sale">
                                    <a href="/collections/sale" rel="prefetch"><span className="menu-section-text text-rose">Sale</span></a>
                                </div>
                            </div>
                            <div className="mobile-menu-link mobile-menu-locations w-full border-t border-oat">
                                <div className="menu-section-header flex items-center w-full py-2" data-nav="locations">
                                    <a className="flex items-center" href="/collections/embroidery-shop">
                                        <div className="menu-icon w-[18px] flex items-center justify-center">
                                            <EmbroideredBlanket width="w-[14px]" />
                                        </div>
                                        <span className="menu-section-text ml-2">Embroidery Shop</span>
                                    </a>
                                </div>
                            </div>
                            <div className="mobile-menu-link mobile-menu-locations w-full border-t border-oat">
                                <div className="menu-section-header flex items-center w-full py-2" data-nav="locations">
                                    <a className="flex items-center" href="/pages/guideshop-locations">
                                        <div className="menu-icon w-[18px] flex items-center justify-center">
                                            <Location width="w-[14px]" />
                                        </div>
                                        <span className="menu-section-text ml-2">Visit A Guideshop</span>
                                    </a>
                                </div>
                            </div>
                            <div className="mobile-menu-link mobile-menu-classes-events w-full border-t border-oat">
                                <div className="menu-section-header flex items-center w-full py-2" data-nav="classes-events">
                                    <a className="flex items-center" href="/pages/classes-and-events">
                                        <div className="menu-icon w-[18px] flex items-center justify-center">
                                            <Calendar width="w-[14px]" />
                                        </div>
                                        <span className="menu-section-text ml-2">Classes + Events</span>
                                    </a>
                                </div>
                            </div>
                            <div className="mobile-menu-link mobile-menu-blog w-full border-t border-oat">
                                <div className="menu-section-header flex items-center w-full py-2" data-nav="blog">
                                    <a className="flex items-center" href="/blogs/ma-edit">
                                        <div className="menu-icon w-[18px] flex items-center justify-center">
                                            <Document width="w-[12px]" />
                                        </div>
                                        <span className="menu-section-text ml-2">Blog</span>
                                    </a>
                                </div>
                            </div>
                            {/* <div className="mobile-menu-link mobile-menu-registry w-full border-t border-oat">
                                <div className="menu-section-header flex items-center w-full py-2" data-nav="registry">
                                    <a className="flex items-center" href="/pages/registry">
                                        <div className="menu-icon w-[18px] flex items-center justify-center">
                                            <OpenBox width="w-[16px]" />
                                        </div>
                                        <span className="menu-section-text ml-2">Registry</span>
                                    </a>
                                </div>
                            </div> */}
                            <div className="mobile-menu-section mobile-menu-help w-full border-t border-oat">
                                <div className="menu-section-header flex items-center w-full py-2" data-nav="help" onClick={handleToggleSubnav}>
                                    <div className="menu-icon w-[18px] flex items-center justify-center">
                                            <Chat width="w-[14px]" />
                                        </div>
                                    <span className="menu-section-text ml-2">Help + Support</span> <span className="ml-auto">{activeSubnav && activeSubnav.help === true ? <Minus width="w-[16px]" /> : <Plus width="w-[18px]" />}</span>
                                </div>
                                <div className={`subnav subnav-help ${activeSubnav && activeSubnav.help ? 'block' : 'hidden'}`} data-subnav="help">
                                    <SubnavHelp activeSubSubnav={activeSubSubnav} setActiveSubSubnav={setActiveSubSubnav} handleToggleSubSubnav={handleToggleSubSubnav} />
                                </div>
                            </div>
                            <div className="mobile-menu-section mobile-menu-account w-full border-t border-oat">
                                <div className="menu-section-header flex items-center w-full pt-2" data-nav="account" onClick={handleToggleSubnav}>
                                    {haveUser ? (
                                        <div className="menu-icon w-[18px] flex items-center justify-center">
                                            <UserSolid width="w-[14px]" />
                                        </div>
                                    ) : (
                                        <div className="menu-icon w-[18px] flex items-center justify-center">
                                            <User width="w-[14px]" />
                                        </div>
                                    )}

                                    <span className="menu-section-text ml-2">Account</span> <span className="ml-auto">{activeSubnav && activeSubnav.account === true ? <Minus width="w-[16px]" /> : <Plus width="w-[18px]" />}</span>
                                </div>
                                <div className={`subnav subnav-account ${activeSubnav && activeSubnav.account ? 'block' : 'hidden'}`} data-subnav="account">
                                    <SubnavAccount activeSubSubnav={activeSubSubnav} setActiveSubSubnav={setActiveSubSubnav} handleToggleSubSubnav={handleToggleSubSubnav} />
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    )
}

export default NavMobileMenu
