import React from 'react'
import Plus from '@components/Icons/Plus.jsx'
import Minus from '@components/Icons/Minus.jsx'

import * as nav from '@data/navdata'

const SubnavBaby = ({
    activeSubSubnav,
    setActiveSubSubnav,
    handleToggleSubSubnav,
}) => {
    return (
        <nav className="subnav-wrap" role="navigation">
            <ul className="nostyle">
                <li className="py-1 text-sm font-normal">
                    <a href={nav.baby_clothes}>Shop All Baby</a>
                </li>
                <li className="py-1 text-sm font-normal">
                    <a href={nav.baby_new_arrivals}>Baby New Arrivals</a>
                </li>
                <li className="py-1 text-sm font-normal">
                    <a href={nav.baby_rompers}>
                        One-Pieces, Rompers + Bodysuits
                    </a>
                </li>
                <li className="py-1 text-sm font-normal">
                    <a href={nav.baby_shirts}>Shirts + Tops</a>
                </li>
                <li className="py-1 text-sm font-normal">
                    <a href={nav.baby_pants}>Pants + Leggings</a>
                </li>
                <li className="py-1 text-sm font-normal">
                    <a href={nav.baby_shorts}>Shorts</a>
                </li>
                <li className="py-1 text-sm font-normal">
                    <a href={nav.baby_dresses}>Dresses</a>
                </li>
                <li className="py-1 text-sm font-normal">
                    <a href={nav.baby_sweaters}>Sweaters + Sweatshirts</a>
                </li>
                <li className="py-1 text-sm font-normal">
                    <a href={nav.baby_pajamas}>Pajamas</a>
                </li>
                <li className="py-1 text-sm font-normal">
                    <a href={nav.baby_swimwear}>Swimwear</a>
                </li>
                <li className="py-1 text-sm font-normal">
                    <a href={nav.baby_jackets}>Jackets + Outerwear</a>
                </li>
                <li className="py-1 text-sm font-normal">
                    <a href={nav.baby_accessories}>Accessories</a>
                </li>
                <li className="py-1 text-sm font-normal">
                    <a href={nav.baby_hats}>Baby Hats + Headbands</a>
                </li>
                <li className="py-1 text-sm font-normal">
                    <a href={nav.sleep_bags}>Sleep Bags + Swaddles</a>
                </li>
                <li className="py-1 text-sm font-normal">
                    <a href={nav.baby_blankets}>Blankets</a>
                </li>
                <li className="py-1 text-sm font-normal">
                    <a href={nav.baby_burp_cloths}>Burp Cloths + Bibs</a>
                </li>
            </ul>
            <div
                className="pl-3 subsubnav subnav-baby-sizes mt-2"
                data-subnav="baby_sizes"
            >
                <div
                    className="menu-section-header flex items-center w-full py-1"
                    data-subnav="baby_sizes"
                    onClick={handleToggleSubSubnav}
                >
                    <span className="menu-section-text">Shop By Size</span>{' '}
                    <span className="ml-auto">
                        {activeSubSubnav &&
                        activeSubSubnav.baby_sizes === true ? (
                            <Minus width="w-[16px]" />
                        ) : (
                            <Plus width="w-[18px]" />
                        )}
                    </span>
                </div>
                <div
                    className={`subsubnav subsubnav-baby-sizes ${
                        activeSubSubnav && activeSubSubnav.baby_sizes
                            ? 'block'
                            : 'hidden'
                    }`}
                    data-subsubnav="baby_sizes"
                >
                    <ul className="nostyle">
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.preemie}>Preemie</a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.newborn}>Newborn</a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.zero_three}>0–3M</a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.three_six}>3–6M</a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.six_nine}>6–9M</a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.nine_twelve}>9–12M</a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.twelve_eighteen}>12–18M</a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.eighteen_twentyfour}>18–24M</a>
                        </li>

                    </ul>
                </div>
            </div>
            <div
                className="pl-3 subsubnav subnav-baby-sizes mt-2"
                data-subnav="baby_gear"
            >
                <div
                    className="menu-section-header flex items-center w-full py-1"
                    data-subnav="baby_gear"
                    onClick={handleToggleSubSubnav}
                >
                    <span className="menu-section-text">Nursery + Gear</span>{' '}
                    <span className="ml-auto">
                        {activeSubSubnav &&
                        activeSubSubnav.baby_gear === true ? (
                            <Minus width="w-[16px]" />
                        ) : (
                            <Plus width="w-[18px]" />
                        )}
                    </span>
                </div>
                <div
                    className={`subsubnav subsubnav-baby-sizes ${
                        activeSubSubnav && activeSubSubnav.baby_gear
                            ? 'block'
                            : 'hidden'
                    }`}
                    data-subsubnav="baby_gear"
                >
                    <ul className="nostyle">
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.nursery_more}>Shop All Nursery</a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.furniture}>Nursery Furniture</a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.bedding}>Mattresses + Bedding</a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.baby_blankets}>Blankets</a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.monitors_sound_machines}>
                                Monitors + Sound Machines
                            </a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.bouncers_loungers}>
                                Bouncers + Loungers
                            </a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.carriers}>Carriers + Car Seats</a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.strollers}>Strollers</a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.high_chairs}>High Chairs</a>
                        </li>

                        <li className="py-1 text-sm font-normal">
                            <a href={nav.baby_pacifiers}>
                                Pacifiers + Teethers
                            </a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.bathing}>Bathing + Grooming</a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.bottles}>Baby Bottles</a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.toys}>Toys</a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.activity_centers_play_gyms}>
                                Activity Centers + Play Gyms
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div
                className="pl-3 subsubnav subnav-baby-moments mt-2 mb-3"
                data-subnav="baby_moments"
            >
                <div
                    className="menu-section-header flex items-center w-full py-1"
                    data-subnav="baby_moments"
                    onClick={handleToggleSubSubnav}
                >
                    <span className="menu-section-text">Shop By Moment</span>{' '}
                    <span className="ml-auto">
                        {activeSubSubnav &&
                        activeSubSubnav.baby_moments === true ? (
                            <Minus width="w-[16px]" />
                        ) : (
                            <Plus width="w-[18px]" />
                        )}
                    </span>
                </div>
                <div
                    className={`subsubnav subsubnav-baby-moments ${
                        activeSubSubnav && activeSubSubnav.baby_moments
                            ? 'block'
                            : 'hidden'
                    }`}
                    data-subsubnav="baby_moments"
                >
                    <ul className="nostyle">
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.welcoming_baby}>Welcoming Baby</a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.first_feeds}>First Feeds + Nursing</a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.developmental_play}>Developmental Play</a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.bedtime_naptime}>Bedtime + Naptime</a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.bathtime}>Bathtime</a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.starting_solids}>Starting Solids</a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.special_occasions}>
                                Special Occasions
                            </a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.on_the_go}>On The Go</a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.mommy_me}>Matching Family</a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.preemie_essentials}>
                                Preemie Essentials
                            </a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.baby_registry_essentials}>
                                Baby Registry Essentials
                            </a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.daycare_ready}>Daycare Ready</a>
                        </li>
                        <li className="py-1 text-sm font-normal">
                            <a href={nav.packing_your_diaper_bag}>
                                Packing Your Diaper Bag
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default SubnavBaby
